// On load
document.addEventListener('DOMContentLoaded', function() {
    // DLF post listing
    const postEntries = document.querySelectorAll('.dlf-content .avia-content-slider .post-entry');
    
    postEntries.forEach(entry => {

        // Move date stamp to the bottom of container
        const slideContent = entry.querySelector('.slide-content');
        const slideMeta = entry.querySelector('.slide-content .slide-meta');

        if (slideContent && slideMeta) {
            slideContent.removeChild(slideMeta);
            slideContent.appendChild(slideMeta);
        }

        // Set height as the same as parent
        entry.style.height = `${entry.parentElement.clientHeight}px`;

        window.addEventListener('resize', () => {
            entry.style.height = `${entry.parentElement.clientHeight}px`;
        });
    });
});


